import React from "react"
import './home.scss'
import Header from "./header";
import ArcaxImg from '../image/arcax.jpg'



const Arcax=()=>{




    return(
<div className='container'>
			 <Header />
			 <div className='soc-patet'>
						 <img src={ArcaxImg} alt="masis" className='big-img' />
			 </div>
			 <div className="body">
				<span>
					
					<h3>Զբոսաշրջային Փաթեթ 18</h3>
					<p>Ստեփանակերտ  5օր\4գիշերր</p>
					<p>
Անձերի քանակ՝    2 <br />
Արժեքը՝         145,000 ՀՀ դրամ<br />
Սնունդ՝  Նախաճաշ<br />
Գիշերակաց՝  «Եվրոպա »  հյուրանոց  (ստանդարտ երկտեղանի)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  35,000 ՀՀ դրամ<br />
<hr />
•	Ստեփանակերտ<br />
•	Գանձասար
</p>
				</span>
				<span>
					<h3>Զբոսաշրջային Փաթեթ 19</h3>
					<p>Ստեփանակերտ  4օր\3գիշեր</p>
					<p>
Անձերի քանակ՝    2 <br />
Արժեքը՝         48,000 ՀՀ դրամ<br />
Սնունդ՝  Նախաճաշ<br />
Գիշերակաց՝  «Մենուա»  հյուրանոց  (ստանդարտ երկտեղանի)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  14,000 ՀՀ դրամ<br />
<hr />
•	Ստեփանակերտ<br />
•	Գանձասար

</p>
				 </span>
				 				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 20</h3>
					<p>Ստեփանակերտ  6օր\5գիշեր</p>
					<p>
		
Անձերի քանակ՝    1 <br />
Արժեքը՝         110,000 ՀՀ դրամ<br />
Սնունդ՝  Նախաճաշ<br />
Գիշերակաց՝  «Նացիոնալ Արցախ»  հյուրանոց  (Դելյուքս մեկտեղանի)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  20,000 ՀՀ դրամ<br />

<hr />
•	Ստեփանակերտ<br />
•	Գանձասար

</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 21</h3>
					<p>Ստեփանակերտ  6օր\5գիշերր</p>
					<p>
Անձերի քանակ՝    2 <br />
Արժեքը՝         80,000 ՀՀ դրամ<br />
Սնունդ՝  Նախաճաշ<br />
Գիշերակաց՝  «ԷԴ-ԷՄ»  հյուրանոց  (ստանդարտ երկտեղանի)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  15,000 ՀՀ դրամ<br />

<hr />
•	Ստեփանակերտ<br />
•	Գանձասար

</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 22</h3>
					<p>Ստեփանակերտ  4օր\3գիշեր</p>
					<p>

					

Անձերի քանակ՝    2 <br />
Արժեքը՝         95,000 ՀՀ դրամ <br />
Սնունդ՝  Նախաճաշ <br />
Գիշերակաց՝  «Պարկ Հոթել Արցախ»  հյուրանոց  (ստանդարտ երկտեղանի) <br />
Հյուրանոցի 1 գիշերվա արժեքը՝  28,800 ՀՀ դրամ <br />

<hr />
•	Ստեփանակերտ<br />
•	Գանձասար
</p>
				</span>
			 </div>
			 
			 <div  className='footer'>
        <p>Copyright ©2023. Masis Tour: all rights reserved. </p>
    </div>
    </div>
    )
}

export default Arcax

