import React from "react";
import Header from "./header";
import VayocDzorImg from "../image/vayoc-dzor.jpg";



const VayocDzor=()=>{




    return(
<div className='container'>
			 <Header />
			 <div className='soc-patet'>
				 <img src={VayocDzorImg} alt="masis" className='big-img' />
			 </div>
			 <div className="body">
				<span>
					
					<h3>Զբոսաշրջային Փաթեթ 7</h3>
					<p>Ջերմուկ  5օր\4գիշեր</p>
					<p>

					

Անձերի քանակ՝    2 <br />
Արժեքը՝         95,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, <br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Անի»  հյուրանոց  (Երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  20,000 ՀՀ դրամ<br />
<hr />
•	Գնդևանք<br />
•	Ըմպելասրահ  

</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 8</h3>
					<p>Ջերմուկ  5օր\4գիշեր</p>
					<p>

Անձերի քանակ՝    1 <br />
Արժեքը՝         87,000 ՀՀ դրամ <br />
Սնունդ՝ Նախաճաշ, ճաշ, ընթրիք <br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում <br />
Գիշերակաց՝  «Ջերմուկ 1»  առողջարան  (մեկտեղանի ստանդարտ համար) <br />
Հյուրանոցի 1 գիշերվա արժեքը՝  18,000 ՀՀ դրամ <br />

<hr />
•	Գնդևանք<br />
•	Ըմպելասրահ  

</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 9</h3>
					<p>Ջերմուկ  5օր\4գիշեր</p>
					<p>

Անձերի քանակ՝    1 <br />
Արժեքը՝         115,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ճաշ, ընթրիք<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Ջերմուկ  Աշխարհ»  առողջարան  (մեկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  25,000 ՀՀ դրամ<br />
<hr />
•	Գնդևանք<br />
•	Ըմպելասրահ  <br />
•	Արենի<br />
•	Ջերմուկ

</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 10</h3>
					<p>Ջերմուկ  5օր\4գիշեր</p>
					<p>

Անձերի քանակ՝    2 <br />
Արժեքը՝         145,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ճաշ, ընթրիք<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Վերոնա»  Հյուրանոց  (երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  32,000 ՀՀ դրամ<br />

<hr />
•	Գնդևանք<br />
•	Ըմպելասրահ  <br />
•	Արենի<br />
•	Ջերմուկ

</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 11</h3>
					<p>Ջերմուկ  7օր\6գիշեր</p>
					<p>

Անձերի քանակ՝    1 <br />
Արժեքը՝         120,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ճաշ, ընթրիք<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Արարատ մոր և մանկան»  առողջարան    (մեկտեղանի էկոնոմ)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  15,000 ՀՀ դրամ<br />
<hr />
•	Գնդևանք<br />
•	Ըմպելասրահ  <br />
•	Արենի<br />
•	Ջերմուկ

</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 12</h3>
					<p>Եղեգնաձոր  4օր\3գիշեր</p>
					<p>

Անձերի քանակ՝    2 <br />
Արժեքը՝         88,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ճաշ, ընթրիք<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Արփա»  հյուրանոց  (երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  26,000 ՀՀ դրամ<br />
<hr />
•	Նորավանք<br />
•	Արենի

</p>
				</span>
	
				 </div>
			 <div  className='footer'>
        <p>Copyright ©2023. Masis Tour: all rights reserved. </p>
    </div>
    </div>
    )
};

export default VayocDzor

