import React from "react"
import './home.scss'
import Header from "./header";
import KotayqImg from '../image/kotayq.jpeg'



const Tours=()=>{




    return(
<div className='container'>
			 <Header />
			 <div className='soc-patet'>
						 <img src={KotayqImg} alt="masis" className='big-img' />
			 </div>
			 <div className="body">
				<span>
					
					<h3>Տուր 1</h3>
					 <p>
					
  Ուղղությունը` Երևան – Սաղմոսավանք, Գյումրի քաղաք <br />
 Տևողությունը՝ 1 օր<br />
 Զբոսավարի ծառայություն՝ առկա է<br />
 Պայմանները՝ 15 տեղանոց mercedes sprinter մակնիշի մեքենա<br />
 Արժեքը` 1 անձի համար 8,000 ՀՀ դրամ
 
</p>
				 </span>
				 <span>
					
					<h3>Տուր 2</h3>
					 <p>
					
Ուղղությունը` Երևան –Խոր Վիրապ,Նորավանք, Շաքիի ջրվեժ, խնձորեսկ, Տաթև <br />
Տևողությունը՝ 2 օր/1գիշերակաց<br />
Սնունդ՝ նախաճաշ<br />
Զբոսավարի ծառայություն՝ առկա է<br />
Պայմանները՝ 15 տեղանոց mercedes sprinter մակնիշի մեքենա<br />
Արժեքը` 1 անձի համար 25,000 ՀՀ դրամ
</p>
				 </span>
				 <span>
					
					<h3>Տուր 3</h3>
					 <p>
	Ուղղությունը` Երևան –Մեղրի բերդ, Փոքր թաղի Սուրբ Հովհաննես, Մեծ Թաղի Սուրբ  <br />
	Աստվածածին, Անապաստանց վանք, Արաքսի հովիտ<br />
Տևողությունը՝ 2 օր/1գիշերակաց<br />
Սնունդ՝ նախաճաշ<br />
Զբոսավարի ծառայություն՝ առկա է<br />
Պայմանները՝ 15 տեղանոց mercedes sprinter մակնիշի մեքենա<br />
Արժեքը` 1 անձի համար 28,000 ՀՀ դրամ

</p>
				</span>
				<span>
					
					<h3>Տուր 4</h3>
					 <p>
Ուղղությունը` Երևան – Ախթալա, Հաղպատ, Սանահին, Քոբայր <br />
Տևողությունը՝ 1 օր<br />
Զբոսավարի ծառայություն՝ առկա է<br />
Պայմանները՝ 15 տեղանոց mercedes sprinter մակնիշի մեքենա<br />
Արժեքը` 1 անձի համար 8,000 ՀՀ դրամ
</p>
				 </span>
				 <span>
					
					<h3>Տուր 5</h3>
					 <p>
Ուղղությունը` Երևան – Սուրբ Հովհաննես, Հին Դիլիջան, Սևանավանք <br />
Տևողությունը՝ 1 օր<br />
Զբոսավարի ծառայություն՝ առկա է<br />
Պայմանները՝ 15 տեղանոց mercedes sprinter մակնիշի մեքենա<br />
Արժեքը` 1 անձի համար 5,000  ՀՀ դրամ

</p>
				</span>
				 </div>
			 <div  className='footer'>
        <p>Copyright ©2023. Masis Tour: all rights reserved. </p>
    </div>
    </div>
    )
}

export default Tours

