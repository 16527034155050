import React from "react"
import './home.scss'
import Header from "./header";
import TavushImg from '../image/tavush.jpeg'


const Tavush=()=>{




    return(
<div className='container'>
			 <Header />
			 <div className='soc-patet'>
						 <img src={TavushImg} alt="masis" className='big-img' />
			 </div>
			 <div className="body">
				<span>
					
					<h3>Զբոսաշրջային Փաթեթ 13</h3>
					<p>Դիլիջան  4օր\3գիշեր</p>
					<p>

					

Անձերի քանակ՝    2 <br />
Արժեքը՝         105,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ճաշ<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Հաղարծին»  հյուրանոց  (երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  30,000 ՀՀ դրամ<br />
<hr />
•	Հաղարծին<br />
•	Գոշավանք<br />
•	Պարզ  լիճ<br />
•	Ջուխտակ վանքային համալիր


</p>
				 </span>
				 <span>
					
					<h3>Զբոսաշրջային Փաթեթ 14</h3>
					<p>Դիլիջան  3օր\2գիշեր</p>
					<p>

Անձերի քանակ՝    2 <br />
Արժեքը՝         65,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Դիլիջան Պարկ Ռեզորտ »  հյուրանոցային համալիր  (երկտեղանի <br />
	ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  27,000 ՀՀ դրամ<br />

<hr />
•	Հաղարծին<br />
•	Գոշավանք<br />
•	Պարզ  լիճ<br />
•	Ջուխտակ վանքային համալիր


</p>
				</span>
				<span>
					<h3>Զբոսաշրջային Փաթեթ 15</h3>
					<p>Դիլիջան  3օր\2գիշեր</p>
					<p>

Անձերի քանակ՝    2 <br />
Արժեքը՝        55,000 ՀՀ դրամ <br />
Սնունդ՝ Նախաճաշ <br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում <br />
Գիշերակաց՝  «Պոպոք Դիլիջան 1»  հյուրանոց  (երկտեղանի ստանդարտ համար) <br />
Հյուրանոցի 1 գիշերվա արժեքը՝  21,000 ՀՀ դրամ <br />
<hr />
•	Հաղարծին<br />
•	Գոշավանք<br />
•	Պարզ  լիճ<br />
•	Ջուխտակ վանքային համալիր
</p>
				 </span>
				 <span>
					<h3>Զբոսաշրջային Փաթեթ 16</h3>
					<p>Ենոքավան  4օր\3գիշեր</p>
					<p>
					
Անձերի քանակ՝    2 <br />
Արժեքը՝         95,000 ՀՀ դրամ<br />
Սնունդ՝  առկա չէ<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Ապագա  Ռեզորտ »  հյուրանոց  (երկտեղանի քոթեջ)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  25,500 ՀՀ դրամ<br />
<hr />
•	Ձիավարում<br />
•	Ձկնորսություն<br />
•	Արշավներ
</p>
				 </span>
				 <span>
					<h3>Զբոսաշրջային Փաթեթ 17</h3>
					<p>Իջևան   4օր\3գիշեր</p>
					<p>
					
Անձերի քանակ՝    2 <br />
Արժեքը՝         90,000 ՀՀ դրամ <br />
Սնունդ՝  Նախաճաշ, ճաշ <br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում <br />
Գիշերակաց՝  «Մայիսյան Կամուրջ »  հյուրանոցային համալիր  (Լյուքս երկտեղանի) <br />
Հյուրանոցի 1 գիշերվա արժեքը՝  23,500 ՀՀ դրամ <br />
<hr />
•	Հաղարծին <br />
•	Ջուխտակ վանքային համալիր <br />
•	Գոշի լիճ

</p>
				</span>
			 </div>
			 <div  className='footer'>
        <p>Copyright ©2023. Masis Tour: all rights reserved. </p>
    </div>
    </div>
    )
}

export default Tavush

