import React from "react"
import './home.scss'
import Header from "./header";
import KotayqImg from '../image/kotayq.jpeg'



const Kotayq=()=>{




    return(
<div className='container'>
			 <Header />
			 <div className='soc-patet'>
						 <img src={KotayqImg} alt="masis" className='big-img' />
			 </div>
			 <div className="body">
				<span>
					
					<h3>Զբոսաշրջային Փաթեթ 1</h3>
					<p>Ծաղկաձոր   3օր\2գիշեր</p>
					<p>

Անձերի քանակ՝    2 <br />
Արժեքը՝         90,000 ՀՀ դրամ<br />
Սնունդ՝  Նախաճաշ<br />
Տրանսպորտի ծառայություն ՝ միակողմանի ուղևորափոխադրում <br />
Գիշերակաց՝   «Էլեգանտ»  հյուրանոցային համալիր (Երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերակացի  արժեքը՝ 40,000 ՀՀ դրամ<br />
<hr />
•	Կեչառիս վանական համալիր<br />
•	Թեղենիսի բարձրունք


</p>
				</span>
				<span>
				<h3>Զբոսաշրջային Փաթեթ 2</h3>
					<p>Ծաղկաձոր  4օր\3գիշեր</p>
					<p>

					
Անձերի քանակ՝    2 <br />
Արժեքը՝         115,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ճաշ, ընթրիք<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Ալպինա»  հյուրանոցային համալիր (Երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերակացի  արժեքը՝  36,000 ՀՀ դրամ<br />
<hr />
•	Կեչառիս վանական համալիր<br />
•	Թեղենիսի բարձրունք

</p>
				 </span>
				 				 <span>
					
								  <h3>Զբոսաշրջային Փաթեթ 3</h3>
					<p>Ծաղկաձոր  3օր\2գիշեր</p>
					<p>
					

Անձերի քանակ՝    2 <br />
Արժեքը՝         80,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ճաշ, ընթրիք<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Ալպինա»  հյուրանոցային համալիր (Երկտեղանի ստանդարտ համար)<br />
						 Հյուրանոցի 1 գիշերակացի  արժեքը՝ 36.000 ՀՀ դրամ<br />
						 <hr />
•	Թեղենիսի բարձրունք<br />
•	Ծաղկաձոր  <br />
•	Կեչառիս  վանական համալիր


</p>
				 </span>
				 <span>
					
				 <h3>Զբոսաշրջային Փաթեթ 4</h3>
					<p>Աղվերան 3օր\2գիշեր</p>
					<p>
Անձերի քանակ՝    2 <br />
Արժեքը՝         72,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Աղվերան Արարատ»  հյուրանոցային համալիր (Երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերակացի  արժեքը՝ 30,000 ՀՀ դրամ<br />
<hr />
•	Բջնի<br />
•	Ծաղկաձոր  <br />
•	Կեչառիս  վանական համալիր



</p>
				 </span>
				 <span>
					
				 <h3>Զբոսաշրջային Փաթեթ 5</h3>
				 <p>Աղվերան 3օր\2գիշեր</p>
					<p>

Անձերի քանակ՝    2 <br />
Արժեքը՝         55,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ<br />
Տրանսպորտի ծառայություն՝  միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Սպորտ ընդ Ռեսթ Հաուս Աղվերան»  հյուրանոցային համալիր <br />
	(Երկտեղանի ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  24,000 ՀՀ դրամ<br />
<hr />
•	Բջնի<br />
•	Ծաղկաձոր  <br />
•	Կեչառիս  վանական համալիր

</p>
				 </span>
				 <span>
					
				 <h3>Զբոսաշրջային Փաթեթ 6</h3>
				 <p>Աղվերան 4օր\3գիշեր
</p>
					<p>

Անձերի քանակ՝    2 <br />
Արժեքը՝         115,000 ՀՀ դրամ<br />
Սնունդ՝ Նախաճաշ, ընթրիք<br />
Տրանսպորտի ծառայություն՝ միակողմանի ուղևորափոխադրում<br />
Գիշերակաց՝  «Պարկ ռեսորթ Աղվերան»  հյուրանոցային համալիր (Երկտեղանի <br />
	ստանդարտ համար)<br />
Հյուրանոցի 1 գիշերվա արժեքը՝  35,000 ՀՀ դրամ<br />
<hr />
•	Բջնի<br />
•	Ծաղկաձոր  


</p>
				 </span>
				 </div>
			 <div  className='footer'>
        <p>Copyright ©2023. Masis Tour: all rights reserved. </p>
    </div>
    </div>
    )
}

export default Kotayq

